<template>
  <section class="bg-kontakt">
    <Header />
  </section>
  <section class="form-map py-custom position-relative overflow-hidden">
        <img class="position-absolute end-0 top-0 index-absolute trouglovi-width" src="../assets/img/trougloviDesno2.png" alt="" srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000
        }
      }"
    />
        <img
      class="position-absolute custom-position-crveni-kontakt index-absolute"
      src="../assets/img/trougaoCrveni.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        y: 0,
        x: 0
      }"
      :enter="{
        y: 100,
        x: 100,
        transition: {
          y: {
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 20000,
          },
             x: {
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 10000,
          },
        },
      }"
    />
            <img
      class="position-absolute custom-position-plavi-kontakt index-absolute"
      src="../assets/img/trougaoPlavi.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        y: 100,
        x: 100
      }"
      :enter="{
        y: 0,
        x: 0,
        transition: {
          y: {
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 20000,
          },
             x: {
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 10000,
          },
        },
      }"
    />
    <div class="wrapper">
      <div class="container-height"
            v-motion
    :initial="{
      opacity: 0,
    }"
    :enter="{
      opacity: 1,
      transition: {
        delay: 1000,
      }
    }">
        <h3 class="text-orange text-uppercase mb-5 fw-bold">
          Kontaktirajte nas
        </h3>
        <div class="d-flex flex-column flex-md-row">
 <form @submit.prevent="slanjeEmail()">
          <div class="mb-3">
            <input
              required
              type="text"
              class="form-control bg-input"
              id="ime"
              placeholder="Ime i Prezime"
              v-model="ime"
            />
          </div>
          <div class="mb-3">
            <input
              required
              type="text"
              class="form-control bg-input"
              id="firma"
              placeholder="Naziv firme"
              v-model="firma"
            />
          </div>
          <div class="mb-3">
            <input
              required
              type="text"
              class="form-control bg-input"
              id="telefon"
              placeholder="Telefon"
              v-model="telefon"
            />
          </div>
          <div class="mb-3">
            <input
              required
              type="email"
              class="form-control bg-input"
              id="email"
              placeholder="E-mail"
              v-model="email"
            />
          </div>

          <div class="mb-3">
            <textarea
              class="form-control bg-input"
              id="poruka"
              rows="7"
              v-model="poruka"
            ></textarea>
            
          </div>
          
          <button :disabled='spinner' class="btn bg-orange text-light px-4 d-block mt-5">
            <span v-if="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="spinner">Šalje se...</span>
            <span v-if="!spinner">Pošalji</span>
          </button>
          <h5 class="text-orange">{{response}}</h5>
        </form>
        <ul class="align-self-center align-self-md-end mb-4 radno-vreme">
          <li><h3 class="text-orange">Radno vreme</h3></li>
          <li>Ponedeljak <strong>7-15h</strong></li>
          <li>Utorak <strong>7-15h</strong></li>
          <li>Sreda <strong>7-15h</strong></li>
          <li>Četvrtak <strong>7-15h</strong></li>
          <li>Petak <strong>7-15h</strong></li>
          <li>Subota <strong>8-12h</strong></li>
          <li>Nedelja <strong>neradna</strong></li>
        </ul>
        </div>
       
      </div>
    </div>
          <ul class="info-container d-flex flex-column flex-md-row justify-content-md-evenly align-items-md-baseline">
        <li><a href="tel:+38124551163" class="d-flex flex-column justify-content-center align-items-center"><div class="icon-round d-flex justify-content-center align-items-center bg-blue text-light mb-2"><font-awesome-icon icon="fas fa-phone" /></div> <span>+381 24 551 163</span> </a> </li>
        <li><a href="mailto:info@jasimpex.rs" class="d-flex flex-column justify-content-center align-items-center"> <div class="icon-round d-flex justify-content-center align-items-center bg-blue text-light mb-2"><font-awesome-icon icon="far fa-envelope" /></div> <span>info@jasimpex.rs</span> </a> </li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/emyMBmzGixArnwqP9" class="d-flex flex-column justify-content-center align-items-center text-center"><div class="icon-round d-flex justify-content-center align-items-center bg-blue text-light mb-2"><font-awesome-icon icon="fas fa-map-marker-alt" /></div> <span>Beogradski put 147,<br> Subotica</span> </a>  </li>
      </ul>
  </section>
  <Footer />
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { ref } from 'vue';
import axios from 'axios';
import querystring from 'querystring'

const spinner = ref(false);
const ime = ref('');
const firma = ref('');
const telefon = ref('');
const email = ref('');
const poruka = ref('Vaša poruka...');
const response = ref('')

const slanjeEmail = () => {
  
  spinner.value=true;
  axios.post('/contact.php',{
    'ime': ime.value,
    'firma': firma.value,
    'email': email.value,
    'telefon': telefon.value,
    'poruka': poruka.value
  })
  .then((res)=>{
  console.log(res)
  spinner.value=false;
  response.value=res.data.response;
  })
  .catch((e)=>{
    spinner.value=false;
    console.log(e)
    response.value=e.data.response;
  })
}
const position = {
    lat: 46.074053, 
    lng:  19.678805
}
</script>

<style lang="scss" scoped>
.bg-kontakt {
  overflow: hidden;
  background: url("../assets/img/Header.png");
  background-position: center;
  background-size: cover;
  width: 100%;


  h2 {
    font-size: 20px;
  }

}
a {
  text-decoration: none !important;
  color: #000 !important;
}
form {
  width: 40rem;
    @media only screen and (max-width: 768px) {
    width: 100%;
  }
.form-control {
  width: 50%;
   @media only screen and (max-width: 768px) {
    width: 100%;
  }

}

textarea {
  color: #6c757d;
  width: 60% !important;

   @media only screen and (max-width: 768px) {
    width: 100%!important;
  }
}
.bg-input {
  background-color: #ececec;
}
}

.icon-round {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 1.5rem;
}
.info-container {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 5px;
        width: 15%;
        background-color: $color-orange;
    }
        &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 5px;
        width: 15%;
        background-color: $color-orange;
    }
}
.container-height {
    height: 40rem;
      @media only screen and (max-width: 768px) {
    height: auto;
  }
}
.custom-position-crveni-kontakt {
  top: 10%;
  left: 5%;
}
.custom-position-plavi-kontakt {
  right: 1rem;
  bottom: 10%;
}

.radno-vreme {
   @media only screen and (max-width: 950px) {
    font-size: 0.8rem;
  }
   @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 1rem;
  }
}
</style>
<template>
  <section class="bg-img position-relative">
    <Header />
    <h1
      class="text-center text-uppercase mt-5 text-light"
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1000,
        },
      }"
    >
      THREE DECADES OF<span class="text-orange"> SUCCESSFUL AND SUSTAINABLE BUSINESS</span>
    </h1>
    <p
      class="text-center mt-3 text-light"
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1200,
        },
      }"
    >
A family company with over 30 years of successful presence in the garment industry, the sale
of fashion textiles, the sale of accessories, and the distribution of tools and textiles, etc.
    </p>
    <router-link
    style="width:10rem"
      to="/en/whoarewe"
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        mx-auto
        mt-3
        btn-hover
      "
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1400,
        },
      }"
    >
      Find out more
    </router-link>
    <CarouselHeader :tekst="tekst"/>
    <ul
      class="
        card-container
        d-none d-md-flex
        position-absolute
        bottom-0
        start-50
        translate-middle-x
      "
    >
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img
            class="item-img"
            src="../assets/img/Jasimpex.svg"
            alt=""
            srcset=""
          />
          <div class="text-center item-text">
            <p >
            The production of high-quality work and promotional uniforms of modern design.
          </p>
          <a href="http://textile.rs/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
          </div>
   

      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/LaFira.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Wholesale of trimmings and cotton fabrics.
          </p>
                    <a href="https://www.instagram.com/lafira.textile/?hl=en" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Bea.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Leading distributor of fashionionale fabrics in Serbia.
          </p>
                    <a href="https://www.shop.bea-group.com/?lang=en" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/DuranteAdesivi.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            The World leader in the production of pneumatic tools.
          </p>
                    <a href="https://www.duranteadesivi.com/en/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Wakol.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            The most optimal price-to-quality ratio of wood industry glue in the Balkans market.
          </p>
                    <a href="https://www.wakol.com/en/foam-adhesives" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
        </div>
      </li>
            <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Sirca.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Reliable producer of water-based sponge adhesive.
          </p>
                    <a href="https://www.sirca.it/en/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Find out more
    </button>
    </a>
        </div>
      </li>
    </ul>
  </section>
  <section class="main py-5 position-relative overflow-hidden">
    <img
      class="position-absolute end-0 top-0 index-absolute trouglovi-width"
      src="../assets/img/trougloviDesno.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000
        }
      }"
    />
    <img
      class="
        position-absolute
        start-0
        bottom-0
        index-absolute
        trouglovi-width
      "
      src="../assets/img/trougloviLevo.png"
      alt=""
      srcset=""
     v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000
        }
      }"
    />
    <img
      class="position-absolute start-50 top-50 strelica"
      src="../assets/img/Strelica.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1000,
        },
      }"
    />
    <div class="wrapper text-blue">
      <div
        class="d-flex flex-column align-items-start mb-3 mb-md-0"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :enter="{
          opacity: 1,
        }"
      >
        <h2 class="mb-5 text-uppercase fw-bold">
          WHO ARE WE?
        </h2>
        <p>
          “Jasimpex” is a company that has a three-decade-long track record of constant growth
through the continuous improvement of its staff, both on individual and team levels, the
development of technology, and the expansion of its offer.
        </p>
        <router-link
          class="btn bg-orange text-light px-4 mt-5 btn-hover"
          to="/en/whoarewe"
          >Find out more</router-link
        >
      </div>
      <div
        class="d-flex flex-column align-items-start align-items-sm-end"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :visibleOnce="{
          opacity: 1,
        }"
      >
        <h2 class="mb-5 text-uppercase fw-bold">
          WHAT DO WE DO?
        </h2>
        <p class="text-end">
         In addition to the production and sale of textiles, the scope of our business also includes the
sale of fashion materials of authentic design, and accessories, the distribution of tools for
furniture production, and the production of prefabricated houses.
        </p>
        <router-link
          class="btn bg-orange text-light px-4 mt-5 btn-hover"
          to="/en/whatdowedo"
          >Find out more</router-link
        >
      </div>
    </div>
  </section>
  <Footer />
</template>

<script setup>
import Header from "@/components/HeaderEng.vue";
import Footer from "@/components/FooterEng.vue";
import CarouselHeader from "@/components/CarouselHeaderEng.vue";

const tekst = {
  jasimpex:
    "The production of high-quality work and promotional uniforms of modern design.",
  fira: "Wholesale of trimmings and cotton fabrics.",
  bea: "Leading distributor of fashionionale fabrics in Serbia.",
  durante: "The World leader in the production of pneumatic tools.",
  wakol: "The most optimal price-to-quality ratio of wood industry glue in the Balkans market.",
  sirca: "Reliable producer of water-based sponge adhesive."
};
</script>

<style lang="scss" scoped>
.bg-img {
  overflow: hidden;
  background: url("../assets/img/Header.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 700px;

  @media only screen and (max-width: 500px) {
     height: auto;
  }
  p {
    line-height: 2;
    width: 80%;
    margin: 0 auto;
  }
}

.main {
  p {
    width: 600px;
    @media only screen and (max-width: 768px) {
      width: 300px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    @media only screen and (max-width: 576px) {
      text-align: left !important;
    }
  }
}

.card-container {
  .item {
    width: 15rem;
    height: 20rem;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    transition: all 0.2s ease-in-out;
    img {
      display: block;
    }
    &:hover {
      transform: scale(1.2);
      -webkit-box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      -moz-box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      @media only screen and (max-width: 1560px) {
        transform: scale(1);
    }
      img {
        display: none;
      }
      .item-text {
        line-height: 1.5;
        display: block;
        color: $bg-blue;
        font-size: 0.8rem;
        @media only screen and (max-width: 1150px) {
    font-size: 0.65rem;
    }
        p {

           @media only screen and (max-width: 1100px) {
      display: none;
    }
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    img {
      width: 80%;
    }
    @media only screen and (max-width: 1850px) {
      width: 12rem;
      height: 20rem;
    }
       @media only screen and (max-width: 1560px) {
        height: 17rem;
      width: 10rem;
    }
    @media only screen and (max-width: 1150px) {
      height: 15rem;
      width: 8rem;
    }
 @media only screen and (max-width: 950px) {
      width: 7rem;
    }
    .item-text {
      display: none;
    }
  }
  .item + .item {
    margin-left: 5rem;
    @media only screen and (max-width: 1450px) {
      margin-left: 2rem;
    }
    @media only screen and (max-width: 880px) {
      margin-left: 1rem;
    }
  }
}
.strelica {
  width: 10rem;
  transform: translate(-50%, -50%) !important;
  @media only screen and (max-width: 600px) {
   display: none;
  }
}

</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/*animation*/
import { MotionPlugin } from '@vueuse/motion'

/*bootstrap*/
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/*Font awesome*/
import FontAwesomeIcon from "@/fontawesome-icons";

createApp(App)
.use(router)
.use(MotionPlugin)
.component("FontAwesomeIcon", FontAwesomeIcon)
.mount('#app')

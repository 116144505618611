<template>
  <router-view v-slot="{ Component, route }">
    <transition name="fade" mode="out-in">
         <div :key="route.name">  
      <component :is="Component"></component>
    </div>
    </transition>
  </router-view>

</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  // font-size: 62.5%;
}
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0!important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}
a {
  text-decoration: none!important;
}
.btn-width {
  width: fit-content;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    // color: #2c3e50;

    &.router-link-exact-active {
      color: $bg-blue;
    }
  }
}
.wrapper {
  width: 70%;
  margin: 0 auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bg-blue {
  background: $bg-blue;
}
.text-orange {
  color: $color-orange;
}
.text-blue {
  color: $bg-blue;
}
.bg-orange {
  background-color: $color-orange!important;
}

.py-custom {
    padding: 7rem 0;
}
.mb-custom {
  margin-bottom: 15rem;
}
.mt-custom {
  margin-top: 15rem;
}
.home-header {
  border-bottom: 5px solid $color-orange;
}
.index-absolute {
  z-index: -1;
}
// .border-top-header {
//   border-top: 5px solid $color-orange!important;
// }
.btn-hover {
transition: all .2s ease-in!important;
  &:hover {
    transform: scale(1.2)!important;
    -webkit-box-shadow: 0px 0px 35px 0px $color-orange;
-moz-box-shadow: 0px 0px 35px 0px $color-orange;
box-shadow: 0px 0px 35px 0px $color-orange;
  }
}
.custom-position-crveni {
  top: 25%;
  left: 5%;
}
.trouglovi-width {
  width: 40rem;
   @media only screen and (max-width: 1200px) {
      width: 30rem;
    }
     @media only screen and (max-width: 950px) {
      width: 20rem;
    }
     @media only screen and (max-width: 500px) {
      filter: opacity(0.4);
    }
}
.bg-gray {
  background-color: $bg-gray;
}
</style>

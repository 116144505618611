<template>
  <section class="bg-delatnost">
    <Header />
    <div class="border-top-header">
      <div class="bg-orange">
        <div
          class="
            wrapper
            moc-spajanja
            d-flex
            flex-column flex-md-row
            align-items-center
            text-light
            py-5
          "
        >
          <h2
            class="text-uppercase fw-bold"
            v-motion
            :initial="{
              opacity: 0,
              y: -100,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 1000,
              },
            }"
          >
            Moć spajanja<span>!</span>
          </h2>
          <p
            v-motion
            :initial="{
              opacity: 0,
              y: -100,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 1200,
              },
            }"
          >
            Jasimpex efikasno povezuje gotov proizvod, <br />
            nudeći klijentima pouzdanija rešenja.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="main py-5 position-relative overflow-hidden">
    <img
      class="position-absolute end-0 top-0 index-absolute trouglovi-width"
      src="../assets/img/trougloviDesno2.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000,
          delay: 1000,
        },
      }"
    />

    <div class="wrapper text-blue">
      <div
        class="d-flex flex-column align-items-start"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :enter="{
          opacity: 1,
          transition: {
            delay: 1500,
          },
        }"
      >
        <h2 class="mb-3 text-uppercase fw-bold">Čime se bavimo?</h2>
        <h3 class="text-uppercase fw-bold mb-3">Proizvodnja</h3>
        <p class="mb-3">
          Bitan deo uspeha čini i struktura naših zaposlenih, sa kojima radimo
          na konstantnom usavršavanju i razvoju.
        </p>
        <p class="mb-3">
          U sopstvenom proizvodnom pogonu fokus je na konfekciji za odrasle, u
          koju ubrajamo klasične I polo majice, dukserice, trenerke, prsluke
          itd., a sa ciljem da vaš brend postane prepoznatljiv, vršimo uslugu
          brendiranja konfekcije sito štampom ili vezom.
        </p>
        <p>
          Kapacitet naše proizvodnje proteže se na 1000m2 gde se svakodnevno
          radi na preko 20 savremenih mašina.
        </p>
      </div>
    </div>
  </section>
  <section class="parallax-proizvodnja"></section>
  <section class="firme py-custom px-2">
    <h3 class="text-uppercase text-center fw-bold mb-5 h3-delatnost">
      Trgovina
    </h3>
    <CarouselHeader :tekst="tekst" />
    <ul class="card-container d-none d-md-flex justify-content-center">
      <li class="item d-flex flex-column">
        <a href="http://textile.rs/" target="_blank" rel="noopener noreferrer">
          <div class="img-container">
            <img src="../assets/img/Jasimpex.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          <strong
            >Veliki izbor kvalitetnih materijala za šivenje na jednom
            mestu.</strong
          >
          Mi smo pouzdani partner već 30 godina našim klijentima sa jedinstvenom
          ponudom materijala, pozamanterije, kao I pomoćnih alata za sve potrebe
          tekstilne industrije.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.instagram.com/lafira.textile/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/LaFira.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          La Fira se za kratak period istakla na tržištu Balkana kao lider u
          distribuciji ekskluzivnih materijala poput ručno rađene čipke iz
          Indije, premium satena, trendi dezeniranih materijala i ostalog.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.shop.bea-group.com/?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Bea.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          BeA je definitivno svetski lider kada su u pitanju pneumatski alati.
          Pored samih alata, ponuda je obogaćena dodatim priborom poput
          balansera, kompresora i municije.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.duranteadesivi.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/DuranteAdesivi.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          Durante je italijanski proizvođač lepila pretežno za drvnu industriju
          koji se na svetskom tržištu ističe kao najoptimalniji proizvod kada je
          u pitanju odnos cene I kvaliteta.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.wakol.com/en/foam-adhesives"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Wakol.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          Wakol je nemački proizvođač lepila koji je poseban zbog svojih
          karakteristika poput strukture i mirisa, obzirom da je reč o lepilima
          vrhunskog kvaliteta koji se proizvode na vodenoj bazi.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.sirca.it/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Sirca.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          Preko 40 godina Sirca je jedan od vodećih svetskih lider u proizvodnji boja i lakova za drvo,
plastiku i staklo. Italijanski gigant neprestano ulaže svoje resurse u razvoj kako novih
proizvoda tako i novih tehnologija kada su u pitanju završni slojevi/premazi.
        </p>
      </li>
    </ul>
  </section>
  <section class="parallax-trgovina"></section>
  <Footer />
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CarouselHeader from "@/components/CarouselHeader.vue";

const tekst = {
  jasimpex: "Veliki izbor kvalitetnih materijala za šivenje na jednom mestu. Mi smo pouzdani partner već 30 godina našim klijentima sa jedinstvenom ponudom materijala, pozamanterije, kao i pomoćnih alata za sve potrebe tekstilne industrije",
  fira: "La Fira se za kratak period istakla na tržištu Balkana kao lider u distribuciji ekskluzivnih materijala poput ručno rađene čipke iz Indije, premium satena, trendi dezeniranih materijala i ostalog.",
  bea: "BeA je definitivno svetski lider kada su u pitanju pneumatski alati. Pored samih alata, ponuda je obogaćena dodatim priborom poput balansera, kompresora i municije.",
  durante: "Durante je italijanski proizvođač lepila pretežno za drvnu industriju koji se na svetskom tržištu ističe kao najoptimalniji proizvod kada je u pitanju odnos cene I kvaliteta.",
  wakol: "Wakol je nemački proizvođač lepila koji je poseban zbog svojih karakteristika poput strukture i mirisa, obzirom da je reč o lepilima vrhunskog kvaliteta koji se proizvode na vodenoj bazi.",
  sirca: "Preko 40 godina Sirca je jedan od vodećih svetskih lider u proizvodnji boja i lakova za drvo, plastiku i staklo. Italijanski gigant neprestano ulaže svoje resurse u razvoj kako novih proizvoda tako i novih tehnologija kada su u pitanju završni slojevi/premazi. A poslednju deceniju su se fokusirali na održivu ekonomiju, vodeći računa o izboru sirovina, potrošnji električne energije, kao i smanjenju emisije štetnih gasova sa namerom da zaštite životnu sredinu u što većoj meri."
};
</script>

<style lang="scss" scoped>
.bg-delatnost {
  overflow: hidden;
  background: url("../assets/img/Header.png");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.main {
  //height: 45rem;
  @media only screen and (max-width: 768px) {
    height: auto;
  }
  p {
    font-size: 1.1rem;
    width: 450px;
    @media only screen and (max-width: 768px) {
      width: 90%;
      text-align: left !important;
    }
    @media only screen and (max-width: 550px) {
      font-size: 1.2rem;
    }
  }
}
.parallax-trgovina {
  background: url("../assets/img/parallax-trgovina.jpg") no-repeat center center
    fixed;
  /* The image used */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-size:100%;

  width: 100%;
  /* Set a specific height */
  height: 32rem;
  z-index: -100;
  @media only screen and (max-width: 800px) {
    background-attachment: unset;
  }
}
.parallax-proizvodnja {
  background: url("../assets/img/parallax-proizvodnja.jpg") no-repeat center
    center fixed;
  /* The image used */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-size:100%;

  width: 100%;
  /* Set a specific height */
  height: 32rem;
  z-index: -100;
  @media only screen and (max-width: 800px) {
    background-attachment: unset;
  }
}
.card-container {
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 15rem;
    border-radius: 10px 10px 0 0;
    border-left: 2px solid $color-orange;
    border-top: 2px solid $color-orange;
    border-right: 2px solid $color-orange;
    @media only screen and (max-width: 1600px) {
      height: 17rem;
    }
    @media only screen and (max-width: 1350px) {
      height: 12rem;
    }
    @media only screen and (max-width: 880px) {
      height: 10rem;
    }
    img {
      width: 100%;
    }
  }
  .item {
    width: 15rem;
  }
  .item + .item {
    margin-left: 5rem;
    @media only screen and (max-width: 1350px) {
      margin-left: 2rem;
    }
    @media only screen and (max-width: 880px) {
      margin-left: 1rem;
    }
  }
  p {
    width: 100%;
    font-size: 1rem;
    border-left: 2px solid $color-orange;
    @media only screen and (max-width: 1650px) {
      height: 16rem;
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 1155px) {
      height: 15rem;
      font-size: 0.7rem;
    }
    @media only screen and (max-width: 999px) {
      height: 20rem;
    }
  }
}
.firme {
  @media only screen and (max-width: 767px) {
    background-color: $color-orange;
  }
}
.moc-spajanja {
  height: 100%;
  h2 {
    font-size: 2.5rem;
    margin-right: 20rem;
    @media only screen and (max-width: 1300px) {
      font-size: 2.5rem;
      margin-right: 10rem;
    }
    @media only screen and (max-width: 900px) {
      margin-right: 0;
    }
  }
  p {
    font-size: 1.2rem;
    @media only screen and (max-width: 1300px) {
      font-size: 1.2rem;
    }
  }
}
.h3-delatnost {
  color: $bg-blue;
  @media only screen and (max-width: 767px) {
    color: #fff;
  }
}
</style>
<template>
  <section class="bg-img position-relative">
    <Header />
    <h1
      class="text-center text-uppercase mt-5 text-light"
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1000,
        },
      }"
    >
      Tri decenije<span class="text-orange"> uspešnog i održivog poslovanja</span>
    </h1>
    <p
      class="text-center mt-3 text-light"
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1200,
        },
      }"
    >
      Porodična firma sa preko 30 godina uspešnog poslovanja u proizvodnji
      konfekcije za odrasle, prodaje modne metraže, sitne galanterije i
      distribucije tekstila i alata…
    </p>
    <router-link
    style="width:8rem"
      to="/mi"
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        mx-auto
        mt-3
        btn-hover
      "
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          delay: 1400,
        },
      }"
    >
      Saznaj više
    </router-link>
    <CarouselHeader :tekst="tekst"/>
    <ul
      class="
        card-container
        d-none d-md-flex
        position-absolute
        bottom-0
        start-50
        translate-middle-x
      "
    >
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img
            class="item-img"
            src="../assets/img/Jasimpex.svg"
            alt=""
            srcset=""
          />
          <div class="text-center item-text">
            <p >
            Proizvodnja radne i promotivne uniforme modernog dizajna i visokog
            kvaliteta. <br />Veleprodaja materijala i pozamanterije.
          </p>
          <a href="http://textile.rs/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
          </div>
   

      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/LaFira.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Vodeći distibuter modne metraže u Srbiji.
          </p>
                    <a href="https://www.instagram.com/lafira.textile/?hl=en" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Bea.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Svetski lider u proizvodnji pneumatskih alata.
          </p>
                    <a href="https://www.shop.bea-group.com/?lang=en" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/DuranteAdesivi.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Najoptimalniji odnos cene i kvaliteta lepila za drvnu industriju na tržištu Balkana.
          </p>
                    <a href="https://www.duranteadesivi.com/en/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
        </div>
      </li>
      <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Wakol.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Pouzdan proizvođač lepila na vodenoj bazi.
          </p>
                    <a href="https://www.wakol.com/en/foam-adhesives" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
        </div>
      </li>
            <li class="item overflow-hidden d-flex align-items-center justify-content-center">
          <img src="../assets/img/Sirca.svg" alt="" srcset="" />
        <div class="text-center item-text">
             <p >
            Industrijske boje i lakovi za drvo sa garancijom “Made in Italy”.
          </p>
                    <a href="https://www.sirca.it/en/" target="_blank" rel="noopener noreferrer">
              <button
      class="
        btn
        bg-orange
        text-light
        px-4
        d-block
        btn-width
        mx-auto
        mt-5
      " >
      Saznaj više
    </button>
    </a>
        </div>
      </li>
    </ul>
  </section>
  <section class="main py-5 position-relative overflow-hidden">
    <img
      class="position-absolute end-0 top-0 index-absolute trouglovi-width"
      src="../assets/img/trougloviDesno.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000
        }
      }"
    />
    <img
      class="
        position-absolute
        start-0
        bottom-0
        index-absolute
        trouglovi-width
      "
      src="../assets/img/trougloviLevo.png"
      alt=""
      srcset=""
     v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000
        }
      }"
    />
    <img
      class="position-absolute start-50 top-50 strelica"
      src="../assets/img/Strelica.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1000,
        },
      }"
    />
    <div class="wrapper text-blue">
      <div
        class="d-flex flex-column align-items-start mb-3 mb-md-0"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :enter="{
          opacity: 1,
        }"
      >
        <h2 class="mb-5 text-uppercase fw-bold">
          Ko smo mi?
        </h2>
        <p>
          "Jasimpex" je kompanija koja beleži evidentan rast već tri decenije, i
          to kroz unapređenje zaposlenih- kako pojedinaca, tako i timova, razvoj
          tehnologije i proširenje asortimana.
        </p>
        <router-link
          class="btn bg-orange text-light px-4 mt-5 btn-hover"
          to="/mi"
          >Saznaj više</router-link
        >
      </div>
      <div
        class="d-flex flex-column align-items-start align-items-sm-end"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :visibleOnce="{
          opacity: 1,
        }"
      >
        <h2 class="mb-5 text-uppercase fw-bold">
          Čime se bavimo?
        </h2>
        <p class="text-end">
          Pored proizvodnje i prodaje tekstila, bavimo se prodajom modne metraže
          autentičnog dizajna, sitne galanterije, distribucijom alata za
          proizvodnju nameštaja i proizvodnje montažnih kuća…
        </p>
        <router-link
          class="btn bg-orange text-light px-4 mt-5 btn-hover"
          to="/cimesebavimo"
          >Saznaj više</router-link
        >
      </div>
    </div>
  </section>
  <Footer />
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CarouselHeader from "@/components/CarouselHeader.vue";

const tekst = {
  jasimpex:
    "Proizvodnja radne i promotivne uniforme modernog dizajna i visokog kvaliteta. Veleprodaja materijala i pozamanterije.",
  fira: "Vodeći distibuter modne metraže u Srbiji.",
  bea: "Svetski lider u proizvodnji pneumatskih alata.",
  durante: "Najoptimalniji odnos cene i kvaliteta lepila za drvnu industriju na tržištu Balkana.",
  wakol: "Pouzdan proizvođač lepila na vodenoj bazi.",
  sirca: "Industrijske boje i lakovi za drvo sa garancijom “Made in Italy”."
};
</script>

<style lang="scss" scoped>
.bg-img {
  overflow: hidden;
  background: url("../assets/img/Header.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 700px;

  @media only screen and (max-width: 500px) {
     height: auto;
  }
  p {
    line-height: 2;
    width: 80%;
    margin: 0 auto;
  }
}

.main {
  p {
    width: 600px;
    @media only screen and (max-width: 768px) {
      width: 300px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    @media only screen and (max-width: 576px) {
      text-align: left !important;
    }
  }
}

.card-container {
  .item {
    width: 15rem;
    height: 20rem;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    transition: all 0.2s ease-in-out;
    img {
      display: block;
    }
    &:hover {
      transform: scale(1.2);
      -webkit-box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      -moz-box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      box-shadow: 0px -14px 32px 0px rgba(10, 56, 83, 1);
      @media only screen and (max-width: 1560px) {
        transform: scale(1);
    }
      img {
        display: none;
      }
      .item-text {
        line-height: 1.5;
        display: block;
        color: $bg-blue;
        font-size: 0.8rem;
        @media only screen and (max-width: 1150px) {
    font-size: 0.65rem;
    }
        p {

           @media only screen and (max-width: 1100px) {
      display: none;
    }
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    img {
      width: 80%;
    }
    @media only screen and (max-width: 1850px) {
      width: 12rem;
      height: 20rem;
    }
       @media only screen and (max-width: 1560px) {
        height: 17rem;
      width: 10rem;
    }
    @media only screen and (max-width: 1150px) {
      height: 15rem;
      width: 8rem;
    }
 @media only screen and (max-width: 950px) {
      width: 7rem;
    }
    .item-text {
      display: none;
    }
  }
  .item + .item {
    margin-left: 5rem;
    @media only screen and (max-width: 1450px) {
      margin-left: 2rem;
    }
    @media only screen and (max-width: 880px) {
      margin-left: 1rem;
    }
  }
}
.strelica {
  width: 10rem;
  transform: translate(-50%, -50%) !important;
  @media only screen and (max-width: 600px) {
   display: none;
  }
}

</style>
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeViewEng from '../views/HomeViewEng.vue'
import KoSmoMi from '../views/KoSmoMi.vue'
import KoSmoMiEng from '../views/KoSmoMiEng.vue'
import CimeSeBavimo from '../views/CimeSeBavimo.vue'
import CimeSeBavimoEng from '../views/CimeSeBavimoEng.vue'
import VremeplovView from '../views/VremeplovView.vue'
import VremeplovViewEng from '../views/VremeplovViewEng.vue'
import KontaktView from '../views/KontaktView.vue'
import KontaktViewEng from '../views/KontaktViewEng.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/en/home',
    name: 'en/home',
    component: HomeViewEng
  },
  {
    path: '/mi',
    name: 'kosmomi',
    component: KoSmoMi
  },
  {
    path: '/en/whoarewe',
    name: 'whoarewe',
    component: KoSmoMiEng
  },
  {
    path: '/cimesebavimo',
    name: 'cimesebavimo',
    component: CimeSeBavimo
  },
  {
    path: '/en/whatdowedo',
    name: 'whatdowedo',
    component: CimeSeBavimoEng
  },
  {
    path: '/vremeplov',
    name: 'vremeplov',
    component: VremeplovView
  },
  {
    path: '/en/timeline',
    name: 'timeline',
    component: VremeplovViewEng
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: KontaktView
  },
  {
    path: '/en/contact',
    name: 'contact',
    component: KontaktViewEng
  }

  
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHashHistory(),
  routes
})

export default router

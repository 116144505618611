<template>
  <section class="bg-delatnost">
    <Header />
    <div class="border-top-header">
      <div class="bg-orange">
        <div
          class="
            wrapper
            moc-spajanja
            d-flex
            flex-column flex-md-row
            align-items-center
            text-light
            py-5
          "
        >
          <h2
            class="text-uppercase fw-bold"
            v-motion
            :initial="{
              opacity: 0,
              y: -100,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 1000,
              },
            }"
          >
            THE POWER OF CONNECTION!
          </h2>
          <p
            v-motion
            :initial="{
              opacity: 0,
              y: -100,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 1200,
              },
            }"
          >
            Jasimpex connects the final product <br />
            with the clients efficiently by offering reliable solutions.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="main py-5 position-relative overflow-hidden">
    <img
      class="position-absolute end-0 top-0 index-absolute trouglovi-width"
      src="../assets/img/trougloviDesno2.png"
      alt=""
      srcset=""
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 3000,
          delay: 1000,
        },
      }"
    />

    <div class="wrapper text-blue">
      <div
        class="d-flex flex-column align-items-start"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :enter="{
          opacity: 1,
          transition: {
            delay: 1500,
          },
        }"
      >
        <h2 class="mb-3 text-uppercase fw-bold">WHAT DO WE DO?</h2>
        <h3 class="text-uppercase fw-bold mb-3">Production</h3>
        <p class="mb-3">
          A large component of our success is due to the structure of our staff, with whom we
constantly work on improvement and development.
        </p>
        <p class="mb-3">
         Our apparel manufactury is focused on casual and work wear including T-shirts polo shirts,
sweatshirts, tracksuits, vests, etc. In order to make your brand recognizable, we provide the
service of garment branding through screen printing or embroidery.
        </p>
        <p>
          The capacity of our production facilities extends over 1000m2 of space with over 20 new and
modern machines.
        </p>
      </div>
    </div>
  </section>
  <section class="parallax-proizvodnja"></section>
  <section class="firme py-custom px-2">
    <h3 class="text-uppercase text-center fw-bold mb-5 h3-delatnost">
      Trade
    </h3>
    <CarouselHeader :tekst="tekst" />
    <ul class="card-container d-none d-md-flex justify-content-center">
      <li class="item d-flex flex-column">
        <a href="http://textile.rs/" target="_blank" rel="noopener noreferrer">
          <div class="img-container">
            <img src="../assets/img/Jasimpex.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          <strong
            >A diverse choice of quality garment materials in a single location.</strong
          >
     We have been a
reliable partner to our clients for 30 years with a unique offer of materials, trimmings, as well
as accessory tools meeting all the needs of the textile industry.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.instagram.com/lafira.textile/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/LaFira.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          La Fira rose to prominence in the Balkan market very quickly as a leader in the distribution
of exclusive materials such as handmade lacework from India, premium satin, trendy design
materials, etc.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.shop.bea-group.com/?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Bea.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          BeA is a world leader when it comes to pneumatic tools. Besides the tools themselves, the
offer is enriched with additional equipment such as balancers, compressors, and ammunition.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.duranteadesivi.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/DuranteAdesivi.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          Durante is an Italian producer of adhesives primarily for the wood industry, which stands out
in the global market as the most optimal product in terms of the ratio between price and
quality.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.wakol.com/en/foam-adhesives"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Wakol.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          Wakol is a German producer of adhesives unique for their characteristics, such as structure
and odor, given the fact that these are water-based glues of top-notch quality.
        </p>
      </li>
      <li class="item">
        <a
          href="https://www.sirca.it/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="img-container">
            <img src="../assets/img/Sirca.svg" alt="" srcset="" />
          </div>
        </a>
        <p class="p-3">
          For over 40 years, Sirca has been one of the leading global leaders in the production of
paints and varnishes for wood, plastic, and glass. The Italian giant constantly invests in its
resources for the development of products as well as new technologies when it comes to
finishing layers/topcoats.
        </p>
      </li>
    </ul>
  </section>
  <section class="parallax-trgovina"></section>
  <Footer />
</template>

<script setup>
import Header from "@/components/HeaderEng.vue";
import Footer from "@/components/FooterEng.vue";
import CarouselHeader from "@/components/CarouselHeaderEng.vue";

const tekst = {
  jasimpex: "A diverse choice of quality garment materials in a single location. We have been a reliable partner to our clients for 30 years with a unique offer of materials, trimmings, as well as accessory tools meeting all the needs of the textile industry.",
  fira: "La Fira rose to prominence in the Balkan market very quickly as a leader in the distribution of exclusive materials such as handmade lacework from India, premium satin, trendy design materials, etc.",
  bea: "BeA is a world leader when it comes to pneumatic tools. Besides the tools themselves, the offer is enriched with additional equipment such as balancers, compressors, and ammunition.",
  durante: "Durante is an Italian producer of adhesives primarily for the wood industry, which stands out in the global market as the most optimal product in terms of the ratio between price and quality.",
  wakol: "Wakol is a German producer of adhesives unique for their characteristics, such as structure and odor, given the fact that these are water-based glues of top-notch quality.",
  sirca: "For over 40 years, Sirca has been one of the leading global leaders in the production of paints and varnishes for wood, plastic, and glass. The Italian giant constantly invests in its resources for the development of products as well as new technologies when it comes to finishing layers/topcoats. During the last decade, they have focused on sustainability, paying special attention to the choice of raw materials, the use of electricity, as well as the reduction of harmful gases to protect the environment as much as possible."
};
</script>

<style lang="scss" scoped>
.bg-delatnost {
  overflow: hidden;
  background: url("../assets/img/Header.png");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.main {
  //height: 45rem;
  @media only screen and (max-width: 768px) {
    height: auto;
  }
  p {
    font-size: 1.1rem;
    width: 450px;
    @media only screen and (max-width: 768px) {
      width: 90%;
      text-align: left !important;
    }
    @media only screen and (max-width: 550px) {
      font-size: 1.2rem;
    }
  }
}
.parallax-trgovina {
  background: url("../assets/img/parallax-trgovina.jpg") no-repeat center center
    fixed;
  /* The image used */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-size:100%;

  width: 100%;
  /* Set a specific height */
  height: 32rem;
  z-index: -100;
  @media only screen and (max-width: 800px) {
    background-attachment: unset;
  }
}
.parallax-proizvodnja {
  background: url("../assets/img/parallax-proizvodnja.jpg") no-repeat center
    center fixed;
  /* The image used */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-size:100%;

  width: 100%;
  /* Set a specific height */
  height: 32rem;
  z-index: -100;
  @media only screen and (max-width: 800px) {
    background-attachment: unset;
  }
}
.card-container {
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 15rem;
    border-radius: 10px 10px 0 0;
    border-left: 2px solid $color-orange;
    border-top: 2px solid $color-orange;
    border-right: 2px solid $color-orange;
    @media only screen and (max-width: 1600px) {
      height: 17rem;
    }
    @media only screen and (max-width: 1350px) {
      height: 12rem;
    }
    @media only screen and (max-width: 880px) {
      height: 10rem;
    }
    img {
      width: 100%;
    }
  }
  .item {
    width: 15rem;
  }
  .item + .item {
    margin-left: 5rem;
    @media only screen and (max-width: 1350px) {
      margin-left: 2rem;
    }
    @media only screen and (max-width: 880px) {
      margin-left: 1rem;
    }
  }
  p {
    width: 100%;
    font-size: 1rem;
    border-left: 2px solid $color-orange;
    @media only screen and (max-width: 1650px) {
      height: 16rem;
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 1155px) {
      height: 15rem;
      font-size: 0.7rem;
    }
    @media only screen and (max-width: 999px) {
      height: 20rem;
    }
  }
}
.firme {
  @media only screen and (max-width: 767px) {
    background-color: $color-orange;
  }
}
.moc-spajanja {
  height: 100%;
  h2 {
    font-size: 2.5rem;
    margin-right: 20rem;
    @media only screen and (max-width: 1300px) {
      font-size: 2.5rem;
      margin-right: 10rem;
    }
    @media only screen and (max-width: 900px) {
      margin-right: 0;
    }
  }
  p {
    font-size: 1.2rem;
    @media only screen and (max-width: 1300px) {
      font-size: 1.2rem;
    }
  }
}
.h3-delatnost {
  color: $bg-blue;
  @media only screen and (max-width: 767px) {
    color: #fff;
  }
}
</style>